<template>
    <div class="container">
        <crud ref="crud" :options="options"></crud>
    </div>
</template>

<script>
export default {
    name: "List",
    data: function () {
        return {
            options: {
                addBtn: true,
                editBtn: true,
                delBtn: true,
                pager: true,
                addUrl: 'admin.post.add',
                addPerm: 'admin_post_add',
                editUrl: 'admin.post.edit',
                editPerm: 'admin_post_edit',
                delUrl: 'admin.post.delete',
                delPerm: 'admin_post_delete',
                listUrl: 'admin.post.page',
                formLabelWidth: '100px',
                columns: [
                    {prop: "name", label: "名称", required: true},
                    {prop: "code", label: "编码",},
                    {prop: "sort", label: "排序", type: "number", value: 0},
                    {prop: "remark", label: "备注",}
                ],
            }
        }
    },
    methods: {}
}
</script>

<style scoped>

</style>
